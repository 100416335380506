import classNames from 'classnames'
import * as React from 'react'
import { Tooltip, type PlacesType } from 'react-tooltip'
import { useTheme } from 'src/state/providers/Theme'

interface Props {
  readonly label?: React.ReactNode
  readonly labelClassname?: string
  readonly placement?: PlacesType
  readonly children?: React.ReactNode
}

function CalendarPopoverComponent({ children, label, placement, labelClassname }: Props): React.ReactElement | null {
  const id = React.useId()
  const theme = useTheme()

  return (
    <>
      <button
        type="button"
        data-tooltip-id={id}
        data-tooltip-event="click focus"
        data-tooltip-event-off="blur"
        className={classNames(
          'calendar-cutom-event group relative max-w-full truncate break-all text-left align-middle duration-100 ease-in-out after:absolute after:inset-[-5px] after:content-[""] hover:bg-primaryGray/20',
          labelClassname
        )}
        data-testid={`eventButton/${label}`}
      >
        {label}
      </button>
      <Tooltip
        id={id}
        place={placement}
        variant={theme === 'dark' ? 'dark' : 'light'}
        className="!bg-primarywhite !z-[100] mb-1 max-w-sm whitespace-pre-line break-words p-1"
        clickable
        opacity={1}
        globalCloseEvents={{ clickOutsideAnchor: true }}
        data-testid={`calendarTooltip/${label}`}
      >
        {children}
      </Tooltip>
    </>
  )
}
export default React.memo(CalendarPopoverComponent)
