import { type EventApi } from '@fullcalendar/core'
import * as React from 'react'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import type * as base from 'src/api/base'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { errorMessage } from 'src/helpers/fns'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import CalendarPopoverComponent from 'src/views/components/CalendarPopoverComponent'
import Swal from 'sweetalert2'

interface EventContentProps {
  readonly event: EventApi
  readonly revalidate?: () => unknown
  readonly dayGrid?: boolean
  readonly isLecturer?: boolean
  readonly labelClassname?: string
}

export default function CalendarEventContent(props: EventContentProps): React.ReactElement {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const deleteEvenet = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        await Api.deleteLecturerCalendar({
          headers,
          args: {
            id,
          },
        })
        props.revalidate && void props.revalidate()

        toast.success(t('calendar:event_deleted'))
      } catch (error) {
        toast.error(errorMessage(error as base.CommonError))
      }
    },
    [headers, props, t]
  )

  return (
    <div className="flex items-center overflow-hidden">
      <CalendarPopoverComponent placement="top" label={props.event.title} labelClassname={props.labelClassname}>
        {props.event.title}
      </CalendarPopoverComponent>
      {props.isLecturer && props.event.extendedProps.eventType === 'event' && (
        <button
          className="sm:x-auto ml-auto"
          onClick={() => {
            void (async () => {
              const alert = await Swal.fire({
                title: t('common:do_you_really_want_to_delete'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0D6EFD',
                cancelButtonColor: '#6C757D',
                confirmButtonText: t('common:confirm'),
                cancelButtonText: t('common:cancel'),
              })
              if (alert.isConfirmed) {
                try {
                  await deleteEvenet(props.event.extendedProps.eventId)
                } catch (error) {
                  console.error(error)
                }
              }
            })()
          }}
        >
          <DeleteIcon color="white" className="w-[14px] xxs:w-[10px] xs:w-[10px]" />
        </button>
      )}
    </div>
  )
}
